.error-boundary {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $container-spacing-unit;

    .error__content {
        @include media-query-min(md) {
            width: 500px;
        }
    }
}
