.qr_code {
    .form-control {
        width: unset;
    }

    .file-input {
        position: relative;
        display: inline-block;

        input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%;
        }

        svg {
            cursor: pointer;

            path {
                fill: $black;
            }
        }
    }
}
