.mileage__info {
    .end_of_trace_table {
        border-collapse: separate;
        border-spacing: 0;
        table-layout: fixed;
        width: 100%;

        td {
            padding: 5px 10px;
            border-bottom: 1px solid $grey-400;
            border-right: 1px solid $grey-400;
        }

        thead tr:first-child td {
            border-top: 1px solid $grey-400;
        }

        tr td:first-child {
            border-left: 1px solid $grey-400;
        }

        thead tr:first-child td:first-child { 
            border-top-left-radius: 4px;
        }
        thead tr:first-child td:last-child { 
            border-top-right-radius: 4px; 
        }
        
        tbody tr:last-child td:first-child { 
            border-bottom-left-radius: 4px; 
        }
        tbody tr:last-child td:last-child { 
            border-bottom-right-radius: 4px; 
        }
    }

    hr.hard {
        border-top: 1px solid $grey-400;
    }
}
