.selected-files {
    width: 100%;

    .file {
        position: relative;
        display: inline-block;
        width: 200px;
        height: 200px;
        margin: 1rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border-radius: 5px;

        .exit-icon {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
            transition: transform .1s ease-out;

            &:hover,
            &:active {
                cursor: pointer;
                transform: translate(50%, -50%) scale(1.1);
            }
        }
    }
}

.mileage-form {
    width: 100%;
    min-height: 200px;
    max-height: 90%;

    @include media-query-min(md) {
        width: 80%;
    }

    @include media-query-min(lg) {
        width: 40%;
    }
}

.btn-refresh {
    border: 0;
    background: transparent;
    padding: .25rem .5rem;
    border-radius: .25rem;

    &:hover {
        background: $grey-200;
        cursor: pointer;
    }

    svg {
        margin-bottom: 2px;
    }
}
