.Form {
    .content {
        &.disabled {
            opacity: .5;
            pointer-events: none;
        }
    }
}

.form-control {
    border-radius: 2px;
    border: 1px solid $grey-400;

    &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid $grey-700;
    }

    &.error {
        border: 1px solid #cc3300;
    }

    &.form-control--checkbox {
        width: unset;
    }
}
