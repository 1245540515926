@mixin horizontal-gutter {
    margin-left: $container-margin-h;
    margin-right: $container-margin-h;

    @include media-query-max(sm) {
        margin-left: $container-margin-h-sm;
        margin-right: $container-margin-h-sm;
    }
}

@mixin vertical-gutter {
    margin-bottom: $container-margin-v;
    margin-top: $container-margin-v;

    @include media-query-max(sm) {
        margin-bottom: $container-margin-v-sm;
        margin-top: $container-margin-v-sm;
    }
}

@mixin children-vertical-gutter {
    > *:not(.container--column):not(.container--wrap) {
        @include vertical-gutter;
    }
}

@mixin children-horizontal-gutter {
    > *:not(.container--row) {
        @include horizontal-gutter;
    }
}

@mixin container-padding {
    padding: $container-padding-v $container-padding-h;

    @include media-query-max(sm) {
        padding: $container-padding-v-sm $container-padding-h-sm;
    }
}

@mixin media-query(
    $media-query-size,
    $lower-bound: false,
    $upper-bound: false
) {
    @if map-has-key($ranges, $media-query-size) {
        $range: map-get($ranges, $media-query-size);

        @if $lower-bound and $upper-bound {
            @media (min-width: lower-bound($range)) and (max-width: upper-bound($range)) { @content; }
        }

        @else if $lower-bound {
            @media (min-width: lower-bound($range)) { @content; }
        }

        @else if $upper-bound {
            @media (max-width: upper-bound($range)) { @content; }
        }
    }

    @else {
        @error "Invalid breakpoint range value `#{$media-query-size}`";
    }
}

@mixin media-query-max($media-query-size) {
    @if type-of($media-query-size) == number {
        @media (max-width: $media-query-size) { @content; }
    }

    @else {
        @include media-query($media-query-size, $upper-bound: true) { @content; }
    }
}

@mixin media-query-min($media-query-size) {
    @if type-of($media-query-size) == number {
        @media (min-width: $media-query-size) { @content; }
    }

    @else {
        @include media-query($media-query-size, $lower-bound: true) { @content; }
    }
}

@mixin media-query-only($media-query-size) {
    @include media-query($media-query-size, $lower-bound: true, $upper-bound: true) { @content; }
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;
    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_")
    );
    $formats: (
        otf: "opentype",
        ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @at-root {
        @font-face {
            font-family: quote($name);
            font-style: $style;
            font-weight: $weight;
            src: $src;
        }
    }
}

@mixin text(
    $font-family: $font-family,
    $font-size: $font-size-base,
    $line-height: $line-height-base,
    $font-weight: $font-weight,
    $font-size-sm: $font-size,
    $line-height-sm: $line-height
) {
    color: $default-color;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;

    @include media-query-max(sm) {
        @if $font-size-sm != $font-size {
            font-size: $font-size-sm;
        }

        @if $line-height-sm != $line-height {
            line-height: $line-height-sm;
        }
    }
}
