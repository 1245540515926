.notification_message_animation-enter {
    opacity: 0.01;
    transform: translateY(-30px);
}

.notification_message_animation-enter.notification_message_animation-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 200ms ease-in;
}

.notification_message_animation-exit {
    opacity: 1;
    transform: translateY(0);
}

.notification_message_animation-exit.notification_message_animation-exit-active {
    opacity: 0.01;
    transform: translateY(-30px);
    transition: all 200ms ease-in;
}

.notification_message {
    &.alert {
        padding: .5rem .75rem;
        border: none;
        font-size: .9rem;
    }

    &.alert-normal {
        background-color: #F0EFC0;
        color: black;
    }

    &.alert-success {
        background-color: #36B37E;
        color: white;
    }

    &.alert-danger {
        background-color: #FF5630;
        color: white;
    }

    &.alert .close {
        text-shadow: none;
        font-weight: normal;
        opacity: .6;
        color: white;
        line-height: 1.2rem;
    }

    &.alert-normal .close {
        color: black;
    }

    &.alert .close:hover {
        opacity: 1;
    }
}
