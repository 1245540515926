.hide-sm {
    @include media-query-max(sm) {
        display: none !important;
    }
}

.hide-md {
    @include media-query-max(md) {
        display: none !important;
    }
}

.hide-lg {
    @include media-query-min(md) {
        display: none !important;
    }
}

.hide {
    display: none !important;
    visibility: hidden;
}

.disabled {
    pointer-events: none !important;
}

.border-0 {
    border: 0;
}

@each $num in (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12) {
    .flex--#{$num} {
        flex: $num;
    }
}
