html {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100vh;
    @include text();
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

img {
    max-width: 100%;
}

a {
    color: $blue-600;
    text-decoration: none;

    &:hover {
        color: $blue-600;
    }
}

hr {
    border-bottom: 0;
    border-top: 1px solid $grey-100;
    margin-top: 2*$container-spacing-unit;
    width: 95%;
}
