.RaspberryView {
    width: 45%;
    min-height: 200px !important;
    max-height: 90%;

    .view {
        img {
            width: 100%;
            max-height: 100%;
        }
    }
}
