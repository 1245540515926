.react-datepicker {
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__triangle {
    border-bottom-color: $grey-100;   
}

.react-datepicker__header {
    background: $grey-100;   
}

.react-datepicker__today-button {
    background: $grey-100;
}
