.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range ,
.react-datepicker__quarter-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\00d7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

html {
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100vh;
  color: #000;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
img {
  max-width: 100%;
}
a {
  color: #3182CE;
  text-decoration: none;
}
a:hover {
  color: #3182CE;
}
hr {
  border-bottom: 0;
  border-top: 1px solid #F7FAFC;
  margin-top: 16px;
  width: 95%;
}
.text--h1,
.title--h1 {
  color: #000;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
}
@media (max-width: 46.875em) {
  .text--h1,
.title--h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
.text--h2,
.title--h2 {
  color: #000;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}
@media (max-width: 46.875em) {
  .text--h2,
.title--h2 {
    font-size: 20px;
    line-height: 24px;
  }
}
.text--h3,
.title--h3 {
  color: #000;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
@media (max-width: 46.875em) {
  .text--h3,
.title--h3 {
    font-size: 18px;
  }
}
.text--h4,
.title--h4 {
  color: #000;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  flex-grow: 1;
}
.title--h1,
.title--h2,
.title--h3,
.title--h4 {
  margin-top: 0;
}
.title--h1,
.title--h2 {
  margin-bottom: 16px;
}
.title--h3,
.title--h4 {
  margin-bottom: 8px;
}
.text--small {
  color: #000;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}
.spinner {
  z-index: 2;
  width: 50px;
  height: 50px;
}
.spinner.spinner--center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.spinner.blue .icon .path {
  stroke: #1971C8;
}
.spinner .icon {
  width: 100%;
  height: 100%;
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
}
.spinner .icon .path {
  stroke: #606568;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite;
}
@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.Form .content.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.form-control {
  border-radius: 2px;
  border: 1px solid #CBD5E0;
}
.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #4A5568;
}
.form-control.error {
  border: 1px solid #cc3300;
}
.form-control.form-control--checkbox {
  width: unset;
}
.Modal > .background {
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}
.Modal > .content {
  position: fixed;
  z-index: 9001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  min-height: 1px;
  overflow-y: scroll;
}
.dropdown {
  position: relative;
}
.dropdown .menu {
  position: absolute;
  z-index: 9001;
  width: 250px;
  margin-top: 5px;
  border: 1px solid #CBD5E0;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.dropdown .menu.left {
  left: 0;
}
.dropdown .menu.right {
  right: 0;
}
.dropdown .menu.center {
  left: 50%;
  transform: translateX(-50%);
}
.dropdown .menu > .dropdown-item {
  display: block;
  padding: 10px;
  color: #000;
}
.dropdown .menu > .dropdown-item:hover {
  cursor: pointer;
  text-decoration: none;
  background-color: #F7FAFC;
}
.dropdown .menu > .dropdown-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.dropdown .menu > .dropdown-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dropdown .menu > .dropdown-item.active {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  background-color: #3182CE;
}
.dropdown .menu > .dropdown-item.disabled {
  pointer-events: none;
}
.dropdown .menu > .dropdown-item.disabled:hover {
  cursor: default;
  background-color: #fff;
}
.notification_message_animation-enter {
  opacity: 0.01;
  transform: translateY(-30px);
}
.notification_message_animation-enter.notification_message_animation-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 200ms ease-in;
}
.notification_message_animation-exit {
  opacity: 1;
  transform: translateY(0);
}
.notification_message_animation-exit.notification_message_animation-exit-active {
  opacity: 0.01;
  transform: translateY(-30px);
  transition: all 200ms ease-in;
}
.notification_message.alert {
  padding: 0.5rem 0.75rem;
  border: none;
  font-size: 0.9rem;
}
.notification_message.alert-normal {
  background-color: #F0EFC0;
  color: black;
}
.notification_message.alert-success {
  background-color: #36B37E;
  color: white;
}
.notification_message.alert-danger {
  background-color: #FF5630;
  color: white;
}
.notification_message.alert .close {
  text-shadow: none;
  font-weight: normal;
  opacity: 0.6;
  color: white;
  line-height: 1.2rem;
}
.notification_message.alert-normal .close {
  color: black;
}
.notification_message.alert .close:hover {
  opacity: 1;
}
.btn {
  font-size: 13px;
  padding: 0.3rem 1rem;
  border: 1px solid;
  border-radius: 2px;
  font-weight: 700;
  transition: all 0.25s ease-in-out;
}
.btn:hover {
  cursor: pointer;
}
.btn.btn-none {
  background: none;
  border: 0;
  box-shadow: none;
}
.btn.btn-primary, .btn.btn-secondary, .btn.btn-danger, .btn.btn-action {
  background-color: #fff;
}
.btn.btn-primary {
  border-color: #F6AD55;
  color: #F6AD55;
}
.btn.btn-primary:hover {
  color: #fff;
  background-color: #F6AD55;
}
.btn.btn-secondary {
  border-color: #2D3748;
  color: #2D3748;
}
.btn.btn-secondary:hover {
  color: #fff;
  background-color: #2D3748;
}
.btn.btn-danger {
  border-color: #F56565;
  color: #F56565;
}
.btn.btn-danger:hover {
  color: #fff;
  background-color: #F56565;
}
.btn.btn-action {
  border: 0;
  color: #000;
}
.btn.btn-link {
  border: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}
.btn.pill {
  border-radius: 100px;
}
.btn.btn-more {
  border: 0;
  color: #3182CE;
  padding: 0;
  font-weight: 400;
}
.btn.btn-more:hover {
  color: #1971C8;
}
.btn.btn-more:focus {
  outline: none;
  box-shadow: none;
}
.btn.btn-stop {
  padding: 0;
  background: none;
  border: 0;
  fill: #4A5568;
}
.btn.btn-stop.active {
  fill: #F56565;
}
.btn.btn-stop:hover path {
  fill: #F56565;
}
.badge.badge-secondary {
  background-color: #606568;
  color: #fff;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__triangle {
  border-bottom-color: #F7FAFC;
}
.react-datepicker__header {
  background: #F7FAFC;
}
.react-datepicker__today-button {
  background: #F7FAFC;
}
.dropzone {
  height: 250px;
  border: 3px dashed rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  transition: border 0.1s ease-out;
}
.dropzone:hover, .dropzone:active {
  border: 3px dashed black;
  cursor: pointer;
}
.dropzone .label {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qr_code .form-control {
  width: unset;
}
.qr_code .file-input {
  position: relative;
  display: inline-block;
}
.qr_code .file-input input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}
.qr_code .file-input svg {
  cursor: pointer;
}
.qr_code .file-input svg path {
  fill: #000;
}
.Login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login .row {
  flex: 1;
  margin-bottom: 50px;
}
.Login .form.fetching {
  pointer-events: none;
  opacity: 0.5;
}
.Dashboard {
  width: 100%;
  min-height: 100vh;
  color: #000;
}
.Dashboard .Sidebar {
  width: 250px;
  height: 100%;
  position: fixed;
  background: #EDF2F7;
}
.Dashboard .Sidebar .nav-link,
.Dashboard .Sidebar .nav-brand {
  padding: 0.75rem 1rem;
}
.Dashboard .Sidebar a.nav-link {
  color: #606568;
}
.Dashboard .Sidebar a.nav-link svg {
  margin-bottom: 2px;
}
.Dashboard .Sidebar a.nav-link svg path {
  fill: #606568;
}
.Dashboard .Sidebar a.nav-link:hover, .Dashboard .Sidebar a.nav-link.active {
  color: #000;
}
.Dashboard .Sidebar a.nav-link:hover svg, .Dashboard .Sidebar a.nav-link.active svg {
  margin-bottom: 2px;
}
.Dashboard .Sidebar a.nav-link:hover svg path, .Dashboard .Sidebar a.nav-link.active svg path {
  fill: #000;
}
.Dashboard .Sidebar .nav-header {
  width: 100%;
  height: calc(50px + 2rem);
  background-color: #EDF2F7;
  padding: 1rem 0;
}
.Dashboard .Sidebar .nav-header .nav-brand {
  width: 100%;
  padding: 0;
}
.Dashboard .Sidebar .nav-content {
  position: absolute;
  top: calc(50px + 2rem);
  bottom: 90px;
  width: 100%;
  overflow-y: scroll;
}
.Dashboard .Sidebar .nav-footer {
  position: absolute;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  bottom: 0;
  background-color: #EDF2F7;
}
.Dashboard .Main {
  position: relative;
  height: 100vh;
  margin-left: 250px;
  background-color: #fff;
}
.Dashboard .Main .Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #EDF2F7;
}
.Dashboard .Main .Header.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}
.Dashboard .Main .List .sub {
  background-color: #F7FAFC;
  border-top: 1px solid #EDF2F7;
  border-bottom: 1px solid #EDF2F7;
  box-shadow: inset 0px 1px 5px 0px #EDF2F7;
}
.Dashboard .Main .List .sub .Row {
  color: #606568;
}
.Dashboard .Main .List .Header,
.Dashboard .Main .List .Row {
  padding: 1rem;
  display: flex;
}
.Dashboard .Main .List .Header .sortable:hover {
  cursor: pointer;
}
.Dashboard .Main .List .Row:hover, .Dashboard .Main .List .Row.selected {
  background-color: #EDF2F7;
}
.Dashboard .Main .List .Row.clickable {
  cursor: pointer;
}
.Dashboard .Main .List .Row .info {
  display: flex;
}
.Dashboard .Main .List .Row .actions > *:last-child {
  text-align: right;
}
.Dashboard .Main .List .paginate {
  background: #fff;
  width: 100%;
  border-top: 1px solid #EDF2F7;
  text-align: center;
}
.Dashboard .Main .List .paginate .page-back,
.Dashboard .Main .List .paginate .page-next {
  color: #000;
}
.Dashboard .Main .List .paginate .page-back:hover,
.Dashboard .Main .List .paginate .page-next:hover {
  color: #3182CE;
  text-decoration: none;
  cursor: pointer;
}
.Dashboard .Main .List .paginate .page-back {
  margin-right: 0.2rem;
}
.Dashboard .Main .List .paginate .page-next {
  margin-left: 0.2rem;
}
.Dashboard .Main .List .paginate .page-number {
  padding: 0.25rem 0.5rem;
  margin: 0 0.2rem;
  color: #000;
  border-radius: 0.25rem;
  transition: all 0.1s;
}
.Dashboard .Main .List .paginate .page-number:hover, .Dashboard .Main .List .paginate .page-number.active {
  text-decoration: none;
  background: #EDF2F7;
  cursor: pointer;
}
.selected-files {
  width: 100%;
}
.selected-files .file {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 200px;
  margin: 1rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
}
.selected-files .file .exit-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transition: transform 0.1s ease-out;
}
.selected-files .file .exit-icon:hover, .selected-files .file .exit-icon:active {
  cursor: pointer;
  transform: translate(50%, -50%) scale(1.1);
}
.mileage-form {
  width: 100%;
  min-height: 200px;
  max-height: 90%;
}
@media (min-width: 46.9375em) {
  .mileage-form {
    width: 80%;
  }
}
@media (min-width: 64em) {
  .mileage-form {
    width: 40%;
  }
}
.btn-refresh {
  border: 0;
  background: transparent;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}
.btn-refresh:hover {
  background: #EDF2F7;
  cursor: pointer;
}
.btn-refresh svg {
  margin-bottom: 2px;
}
.mileage__info .end_of_trace_table {
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}
.mileage__info .end_of_trace_table td {
  padding: 5px 10px;
  border-bottom: 1px solid #CBD5E0;
  border-right: 1px solid #CBD5E0;
}
.mileage__info .end_of_trace_table thead tr:first-child td {
  border-top: 1px solid #CBD5E0;
}
.mileage__info .end_of_trace_table tr td:first-child {
  border-left: 1px solid #CBD5E0;
}
.mileage__info .end_of_trace_table thead tr:first-child td:first-child {
  border-top-left-radius: 4px;
}
.mileage__info .end_of_trace_table thead tr:first-child td:last-child {
  border-top-right-radius: 4px;
}
.mileage__info .end_of_trace_table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}
.mileage__info .end_of_trace_table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}
.mileage__info hr.hard {
  border-top: 1px solid #CBD5E0;
}
.RaspberryView {
  width: 45%;
  min-height: 200px !important;
  max-height: 90%;
}
.RaspberryView .view img {
  width: 100%;
  max-height: 100%;
}
.error-boundary {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
@media (min-width: 46.9375em) {
  .error-boundary .error__content {
    width: 500px;
  }
}
@media (max-width: 46.875em) {
  .hide-sm {
    display: none !important;
  }
}
@media (max-width: 63.9375em) {
  .hide-md {
    display: none !important;
  }
}
@media (min-width: 46.9375em) {
  .hide-lg {
    display: none !important;
  }
}
.hide {
  display: none !important;
  visibility: hidden;
}
.disabled {
  pointer-events: none !important;
}
.border-0 {
  border: 0;
}
.flex--1 {
  flex: 1;
}
.flex--2 {
  flex: 2;
}
.flex--3 {
  flex: 3;
}
.flex--4 {
  flex: 4;
}
.flex--5 {
  flex: 5;
}
.flex--6 {
  flex: 6;
}
.flex--7 {
  flex: 7;
}
.flex--8 {
  flex: 8;
}
.flex--9 {
  flex: 9;
}
.flex--10 {
  flex: 10;
}
.flex--11 {
  flex: 11;
}
.flex--12 {
  flex: 12;
}
/*# sourceMappingURL=index.css.map */
