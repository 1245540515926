.btn {
    font-size: $font-size-small;
    padding: 0.3rem 1rem;
    border: 1px solid;
    border-radius: 2px;
    font-weight: $font-weight-bold;

    transition: all .25s ease-in-out;

    &:hover {
        cursor: pointer;
    }

    &.btn-none {
        background: none;
        border: 0;
        box-shadow: none;
    }

    &.btn-primary,
    &.btn-secondary,
    &.btn-danger,
    &.btn-action {
        background-color: $white;
    }

    &.btn-primary {
        border-color: $orange-400;
        color: $orange-400;

        &:hover {
            color: $white;
            background-color: $orange-400;
        }
    }

    &.btn-secondary {
        border-color: $grey-800;
        color: $grey-800;

        &:hover {
            color: $white;
            background-color: $grey-800;
        }
    }

    &.btn-danger {
        border-color: $red-500;
        color: $red-500;

        &:hover {
            color: $white;
            background-color: $red-500;
        }
    }

    &.btn-action {
        border: 0;
        color: $black;
    }

    &.btn-link {
        border: 0;
        padding: 0;
        font-size: $font-size-base;
        font-weight: $font-weight;
        line-height: $font-size-base;
    }

    &.pill {
        border-radius: 100px;
    }

    &.btn-more {
        border: 0;
        color: $blue-600;
        padding: 0;
        font-weight: $font-weight;

        &:hover {
            color: $status-blue;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    &.btn-stop {
        padding: 0;
        background: none;
        border: 0;
        fill: $grey-700;

        &.active {
            fill: $red-500;
        }

        &:hover {
            path {
                fill: $red-500;
            }
        }
    }
}
