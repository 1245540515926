.text--h1,
.title--h1 {
    @include text($font-family-title, $font-h1, 32px, $font-weight-title, $font-h1-sm, 30px);
}

.text--h2,
.title--h2 {
    @include text($font-family-title, $font-h2, 30px, $font-weight-title, $font-h2-sm, 24px);
}

.text--h3,
.title--h3 {
    @include text($font-family-title, $font-h3, 24px, $font-weight-title, $font-h3-sm);
}

.text--h4,
.title--h4 {
    @include text($font-family-title, $font-h4, 24px, $font-weight-title);

    flex-grow: 1;
}

.title--h1,
.title--h2,
.title--h3,
.title--h4 {
    margin-top: 0;
}

.title--h1,
.title--h2 {
    margin-bottom: 2*$container-spacing-unit;
}

.title--h3,
.title--h4 {
    margin-bottom: $container-spacing-unit;
}

.text--small {
    @include text($font-family, $font-size-small, 18px);
}
