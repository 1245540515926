.Dashboard {
    width: 100%;
    min-height: 100vh;
    color: $default-color;

    .Sidebar {
        width: 250px;
        height: 100%;
        position: fixed;
        background: $grey-200;

        .nav-link,
        .nav-brand {
            padding: .75rem 1rem;
        }

        a.nav-link {
            color: $grey;

            svg {
                margin-bottom: 2px;

                path {
                    fill: $grey;
                }
            }

            &:hover,
            &.active {
                color: $default-color;

                svg {
                    margin-bottom: 2px;

                    path {
                        fill: $default-color;
                    }
                }
            }
        }

        .nav-header {
            width: 100%;
            height: calc(50px + 2rem);
            background-color: $grey-200;
            padding: 1rem 0;

            .nav-brand {
                width: 100%;
                padding: 0;
            }
        }

        .nav-content {
            position: absolute;
            top: calc(50px + 2rem);
            bottom: 90px;
            width: 100%;
            overflow-y: scroll;
        }

        .nav-footer {
            position: absolute;
            width: 100%;
            border-top: 1px solid rgba(0, 0, 0, .07);
            bottom: 0;
            background-color: $grey-200;
        }
    }

    .Main {
        position: relative;
        height: 100vh;
        margin-left: 250px;
        background-color: $white;

        .Header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $white;
            border-bottom: 1px solid $grey-200;

            &.sticky {
                position: sticky;
                top: 0;
                z-index: 100;
            }
        }

        .List {
            .sub {
                background-color: $grey-100;
                border-top: 1px solid $grey-200;
                border-bottom: 1px solid $grey-200;
                box-shadow: inset 0px 1px 5px 0px $grey-200;
                
                .Row {
                    color: $grey;
                }
            }

            .Header, 
            .Row {
                padding: 1rem;
                display: flex;
            }

            .Header {
                .sortable {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .Row {
                &:hover,
                &.selected {
                    background-color: $grey-200;
                }

                &.clickable {
                    cursor: pointer;
                }

                .info {
                    display: flex;
                }

                .actions {
                    > *:last-child {
                        text-align: right;
                    }
                }
            }

            .paginate {
                background: $white;
                width: 100%;
                border-top: 1px solid $grey-200;
                text-align: center;

                .page-back,
                .page-next {
                    color: $black;

                    &:hover {
                        color: $blue-600;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }

                .page-back {
                    margin-right: .2rem;
                }

                .page-next {
                    margin-left: .2rem;
                }

                .page-number {
                    padding: .25rem .5rem;
                    margin: 0 .2rem;
                    color: $black;
                    border-radius: .25rem;

                    transition: all .1s;

                    &:hover,
                    &.active {
                        text-decoration: none;
                        background: $grey-200;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
