.dropdown {
    position: relative;

    .menu {
        position: absolute;
        z-index: $dropdown-layer;
        width: 250px;
        margin-top: 5px;
        border: 1px solid $grey-400;
        border-radius: 2px;
        background: $white;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        &.center {
            left: 50%;
            transform: translateX(-50%);
        }

        > .dropdown-item {
            display: block;
            padding: 10px;
            color: $default-color;

            &:hover {
                cursor: pointer;
                text-decoration: none;
                background-color: $grey-100;
            }

            &:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            &.active {
                color: $white;
                cursor: pointer;
                text-decoration: none;
                background-color: $blue-600;
            }

            &.disabled {
                pointer-events: none;

                &:hover {
                    cursor: default;
                    background-color: $white;
                }
            }
        }
    }
}
