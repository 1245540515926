.spinner {
    z-index: 2;
    width: 50px;
    height: 50px;

    &.spinner--center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    &.blue .icon {
        .path {
            stroke: $status-blue;
        }
    }
  
    .icon {
        width: 100%;
        height: 100%;
        animation: rotate 2s linear infinite;
        
        .path {
            stroke: $grey;
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
        }
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
