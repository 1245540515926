.dropzone {
    height: 250px;
    border: 3px dashed rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    transition: border .1s ease-out;

    &:hover,
    &:active {
        border: 3px dashed black;
        cursor: pointer;
    }

    .label {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
